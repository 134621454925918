<template>
  <b-modal
    id="modalCreateEditLoja"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <!-- DESCRICAO -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Descrição: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.loja.descricao.$error }"
            placeholder="Nome fantasia"
            v-model="$v.loja.descricao.$model"
          />
          <span v-if="!$v.loja.descricao.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.loja.descricao.minLength" class="invalid-feedback">
            O nome deve possuir pelo menos 8 caracteres
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Telefone:</label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.loja.telefone.$error }"
            placeholder="(00) 98888-8888"
            v-model="$v.loja.telefone.$model"
            :mask="['(##) ####-####', '(##) #####-####']"
            :masked="true"
          />
          <span v-if="!$v.loja.telefone.minLength" class="invalid-feedback">
            Formato de telefone inválido <br />
            Ex: (00) 98888-8888 / (00) 8888-8888
          </span>
        </div>
      </div>
      <!-- ENDEREÇO -->
      <div class="form-row">
        <div class="form-group col-sm-8">
          <label>Endereço: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.loja.endereco.$error }"
            placeholder="Rua, Av., Tv..."
            v-model="$v.loja.endereco.$model"
          />
          <span v-if="!$v.loja.endereco.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Nº: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.loja.num_endereco.$error }"
            placeholder="Nº"
            v-model="$v.loja.num_endereco.$model"
          />
          <span v-if="!$v.loja.num_endereco.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>

      <!-- BAIRRO, ESTADO E CIDADE -->
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Bairro:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.loja.bairro.$error }"
            placeholder="Nome do bairro"
            v-model="$v.loja.bairro.$model"
          />
          <span v-if="!$v.loja.bairro.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Estado: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.loja.city_state_id.$error }"
            v-model="$v.loja.city_state_id.$model"
          >
            <option value="">Selecione o estado</option>
            <option
              v-for="state in states"
              :key="state.id"
              :value="state.id"
              :title="`${state.uf} - ${state.name}`"
            >
              {{ state.uf }}
            </option>
          </select>
        </div>
        <div class="form-group col-sm-4">
          <label>Cidade: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.loja.city_id.$error }"
            v-model="$v.loja.city_id.$model"
          >
            <option value="">Selecione uma cidade</option>
            <option
              v-for="city in cities"
              :key="city.id"
              :value="city.id"
              :title="city.name"
            >
              {{ city.name }}
            </option>
          </select>
          <span v-if="!$v.loja.city_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>

      <!-- ENDEREÇO -->
      <div class="form-row">
        <div class="form-group col-sm-8">
          <label>Complemento:</label>
          <input
            type="text"
            class="form-control"
            placeholder="Complemento do endereço"
            v-model="loja.complemento"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>CEP:<span class="text-danger">*</span></label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.loja.cep.$error }"
            placeholder="00000-000"
            v-model="$v.loja.cep.$model"
            :mask="'#####-###'"
            :masked="true"
          />
        </div>
        <span v-if="!$v.loja.cep.required" class="invalid-feedback">
          O campo é obrigatório
        </span>
      </div>

      <div v-if="lojaId" class="form-row">
        <div class="form-group col-sm-6">
          <p>Status: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusInativo"
              value="0"
              v-model="loja.status"
            />
            <label class="form-check-label" for="statusInativo">
              Inativo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusAtivo"
              value="1"
              v-model="loja.status"
            />
            <label class="form-check-label" for="statusAtivo"> Ativo </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'CreateEditLojaModal',

  components: {
    TheMask,
  },

  props: {
    lojaId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loja: {
        city_id: '',
        city_state_id: '',
        descricao: '',
        telefone: '',
        endereco: '',
        num_endereco: '',
        bairro: '',
        cep: '',
        complemento: '',
      },
      disableButton: false,
      states: null,
      cities: null,
      loadDataEditing: false,
    }
  },

  computed: {
    textModal() {
      return this.lojaId ? 'Atualizar' : 'Cadastrar'
    },
  },

  watch: {
    'loja.city_state_id': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.loja.city_id = ''
        }
        if (newValue !== '') {
          this.getCities()
        }
      },
    },
  },

  methods: {
    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            toaster: 'b-toaster-top-center',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              toaster: 'b-toaster-top-center',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCities() {
      try {
        this.cities = await this.$store.dispatch(
          'getCities',
          this.loja.city_state_id,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            toaster: 'b-toaster-top-center',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              toaster: 'b-toaster-top-center',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getLoja() {
      try {
        this.loadDataEditing = true
        this.loja = await this.$store.dispatch('getLoja', this.lojaId)
        this.$bvModal.show('modalCreateEditLoja')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            toaster: 'b-toaster-top-center',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              toaster: 'b-toaster-top-center',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.lojaId) {
            console.log(this.loja)
            response = await this.$store.dispatch('updateLoja', this.loja)
          } else {
            response = await this.$store.dispatch('saveLoja', this.loja)
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            toaster: 'b-toaster-top-center',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              toaster: 'b-toaster-top-center',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                toaster: 'b-toaster-top-center',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    loja: {
      descricao: {
        required,
        minLength: minLength(8),
      },
      telefone: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      city_state_id: { required },
      city_id: { required },
      endereco: { required },
      num_endereco: { required },
      bairro: { required },
      cep: { required },
    },
  },

  mounted() {
    this.getStates()
    if (this.lojaId) {
      this.getLoja()
    } else {
      this.$bvModal.show('modalCreateEditLoja')
    }
  },
}
</script>
