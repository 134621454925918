<template>
  <Layout>
    <InformacaoChaveModal
      v-if="showInformacaoChaveModal"
      :chaveId="chaveIdSelected"
      @closeModal="closeInformacaoChaveModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Chaves e números da sorte</h5>
      </div>
      <div class="card-body">
        <ListChaves :chaves="chaves" @informacaoChave="infoChave" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListChaves from '@/components/administration/rasgadinhas/ListChaves.vue'
import InformacaoChaveModal from '@/components/administration/rasgadinhas/InformacaoChaveModal.vue'

export default {
  name: 'Chaves',

  components: {
    Layout,
    LoadingData,
    ListChaves,
    InformacaoChaveModal,
  },

  data() {
    return {
      title: 'Chaves',
      loadData: true,
      chaves: null,
      showInformacaoChaveModal: false,
      chaveIdSelected: null,
    }
  },

  methods: {
    async getChaves() {
      try {
        this.chaves = await this.$store.dispatch('getChaves')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },
    infoChave(chaveId) {
      this.chaveIdSelected = chaveId || null
      this.showInformacaoChaveModal = true
    },

    closeInformacaoChaveModal(loadListChaves) {
      if (loadListChaves) {
        this.getChaves()
      }
      this.showInformacaoChaveModal = false
    },
  },

  mounted() {
    this.getChaves()
  },
}
</script>
