<template>
  <div class="text-white" style="background-color: green">
    <br />
    <br />
    <h4 class="text-white">O prazo para cadastro de rasgadinhas expirou</h4>
  </div>
</template>

<script>
export default {
  name: 'CadastrarRasgadinhas',
}
</script>
