<template>
  <div class="text-white">
    <h3 class="text-white">O prazo para cadastros expirou.</h3>
  </div>
</template>

<script>
export default {
  name: 'CadastrarUsuario',
}
</script>
