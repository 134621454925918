import { dateTimeSqlToDateTimeBR } from './date'

const createdUpdatedUser = (userName, date) => {
  return userName !== null && userName !== ''
    ? `${userName} em ${dateTimeSqlToDateTimeBR(date)}`
    : '-'
}

const statusTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 1:
      return '<span class="badge badge-success">Ativo</span>'
    case 0:
      return '<span class="badge badge-danger">Inativo</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const statusChaveTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 1:
      return '<span class="badge badge-secondary">Ativa (disponível)</span>'
    case 2:
      return '<span class="badge badge-success">Chave Cadastrada</span>'
    case 3:
      return '<span class="badge badge-secondary">Chave Inativada</span>'
    default:
      return '<span class="badge badge-danger">Indefinida</span>'
  }
}

const statusProjectTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 0:
      return '<span class="badge badge-danger">Reprovado</span>'
    case 1:
      return '<span class="badge badge-warning">Aguardando Aprovação</span>'
    case 2:
      return '<span class="badge badge-primary">Em Andamento</span>'
    case 3:
      return '<span class="badge badge-success">Concluído</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const moneyToCurrencyBr = (value) => {
  if (value !== null && value !== '') {
    const money = parseFloat(value)
    return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }
  return value
}

export {
  createdUpdatedUser,
  statusTranslate,
  statusProjectTranslate,
  moneyToCurrencyBr,
  statusChaveTranslate,
}
