<template>
  <b-modal
    id="modalInformacaoChave"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <div class="card">
      <div class="card-header bg-transparent border-bottom">
        <h5 class="card-title mb-0">Detalhes do Cupom</h5>
      </div>
      <div class="card-body">
        <p><strong>Nº da sorte:</strong> {{ chave.chave_id }}</p>
        <p><strong>Código Promocional:</strong> {{ chave.chave }}</p>
        <hr />
        <p><strong>Nome do participante:</strong> {{ chave.name }}</p>
        <p><strong>CPF:</strong> {{ chave.cpf }}</p>
        <p><strong>Telefone:</strong> {{ chave.phone }}</p>
        <p><strong>E-mail:</strong> {{ chave.email }}</p>
        <p>
          <strong>Cidade/Estado:</strong> {{ chave.city_user }}/{{
            chave.uf_user
          }}
        </p>
        <p><strong>Loja:</strong> {{ chave.loja }}/{{ chave.city_loja }}</p>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'InformacaoChaveModal',

  props: {
    chaveId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      chave: {
        user_id: '',
        chave_id: '',
        loja_id: '',
        chave: '',
        name: '',
        cpf: '',
        phone: '',
        email: '',
        city_user: '',
        uf_user: '',
        loja: '',
        city_loja: '',
        uf_loja: '',
      },
      loadDataEditing: false,
    }
  },

  methods: {
    async getChave() {
      try {
        this.loadDataEditing = true
        this.chave = await this.$store.dispatch('getChaveByChave', this.chaveId)
        console.log(this.chave)
        this.$bvModal.show('modalInformacaoChave')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
          console.log(error)
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },
  },

  mounted() {
    if (this.chaveId) {
      this.getChave()
    } else {
      this.$bvModal.show('modalInformacaoChave')
    }
  },
}
</script>
