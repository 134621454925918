<template>
  <div>
    <div class="row">
      <div
        v-for="rasgadinha in rasgadinhas"
        :key="rasgadinha.chave_id"
        class="col-md-4"
      >
        <b-card bg-variant="warning" class="text-white-50">
          <h5 class="mt-0 mb-4 text-black">
            <i class="bx ri-coupon-line mr-2"></i>
            {{ rasgadinha.chave }}
          </h5>
          <h6 class="text-black">Nº da sorte: {{ rasgadinha.chave_id }}</h6>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col d-inline-flex align-items-center">
        Quantidade de números da sorte: {{ rasgadinhas.length }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListRasgadinhas',

  props: {
    rasgadinhas: {
      type: Array,
      required: true,
    },
  },
}
</script>
