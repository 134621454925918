<template>
  <Layout>
    <PageHeader />
    <div
      class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
    >
      <h5 class="card-title mb-0 text-white">Meus Dados</h5>
    </div>
    <div class="card-body">
      <EditarMeusDados />
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import EditarMeusDados from '@/components/administration/users/EditarMeusDados.vue'

export default {
  name: 'MeusDados',

  components: {
    Layout,
    PageHeader,
    EditarMeusDados,
  },

  data() {
    return {
      title: 'Cadastrar participante',
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },
}
</script>
