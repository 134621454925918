<template>
  <Layout>
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h2 class="card-title mb-0">Minhas Rasgadinhas</h2>
      </div>
      <div v-if="rasgadinhas" class="card-body">
        <ListRasgadinhas :rasgadinhas="rasgadinhas" />
      </div>
      <div v-else>Nenhum código promocional cadastrado</div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListRasgadinhas from '../../../components/administration/rasgadinhas/ListRasgadinhas.vue'

export default {
  name: 'MinhasRasgadinhas',

  components: {
    Layout,
    LoadingData,
    ListRasgadinhas,
  },

  data() {
    return {
      title: 'Minhas Rasgadinhas',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Minhas Rasgadinhas',
          active: true,
        },
      ],
      loadData: true,
      rasgadinhas: null,
    }
  },

  methods: {
    async getRasgadinhasByUser() {
      try {
        const data = await this.$store.dispatch('getChavesByUser')
        if (data) {
          this.rasgadinhas = await this.$store.dispatch('getChavesByUser')
          this.loadData = false
        }
      } catch (error) {
        if (error.validate === 0) {
          this.loadData = false
        }
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },
  },

  mounted() {
    this.getRasgadinhasByUser()
  },
}
</script>
