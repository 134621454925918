<template>
  <Layout>
    <div class="card-shadow bg-transparent">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h2 class="mb-0 text-white font-weight-semibold text-bold">
          Cadastre suas rasgadinhas
        </h2>
      </div>
      <CadastrarRasgadinhas />
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'
import CadastrarRasgadinhas from '../../../components/administration/rasgadinhas/CadastrarRasgadinhas.vue'

export default {
  name: 'Rasgadinhas',

  components: {
    Layout,
    CadastrarRasgadinhas,
  },

  data() {
    return {
      title: 'Cadastrar Rasgadinhas',
      items: [
        {
          text: 'Administração',
        },
        {
          text: 'Cadastrar Rasgadinhas',
          active: true,
        },
      ],
    }
  },
}
</script>
