<template>
  <div class="text-center">
    <span aria-hidden="true" class="spinner-border text-white" />
    <p class="text-white">Carregando...</p>
  </div>
</template>

<script>
export default {
  name: 'LoadingData',
}
</script>
