<template>
  <div class="text-white">
    <form @submit.prevent="save">
      <!-- NOME E DATA DENASCIMENTO -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Nome: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.name.$error }"
            placeholder="Nome Completo"
            v-model="$v.user.name.$model"
          />
          <span
            v-if="!$v.user.name.required"
            class="invalid-feedback text-white"
          >
            O campo é obrigatório
          </span>
          <span
            v-if="!$v.user.name.minLength"
            class="invalid-feedback text-white"
          >
            O nome deve possuir pelo menos 8 caracteres
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Data de nascimento: <span class="text-danger">*</span></label>
          <input
            type="date"
            class="form-control"
            :class="{ 'is-invalid': $v.user.data_nascimento.$error }"
            placeholder="Data de nascimento"
            v-model="$v.user.data_nascimento.$model"
          />
          <span
            v-if="!$v.user.data_nascimento.required"
            class="invalid-feedback text-white"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-2">
          <label>Gênero: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.user.genero.$error }"
            v-model="$v.user.genero.$model"
          >
            <option value="">Selecione o sexo</option>
            <option value="1">Feminino</option>
            <option value="2">Masculino</option>
            <option value="3">Outro</option>
            <option value="4">Não desejo informar</option>
          </select>
          <span
            v-if="!$v.user.genero.required"
            class="invalid-feedback text-white"
          >
            O campo é obrigatório
          </span>
        </div>
      </div>
      <!-- CPF E RG -->
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>CPF: <span class="text-danger">*</span></label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.cpf.$error }"
            placeholder="000.000.000-00"
            v-model="$v.user.cpf.$model"
            :mask="'###.###.###-##'"
            :masked="true"
          />
          <span
            v-if="!$v.user.cpf.required"
            class="invalid-feedback text-white"
          >
            O campo é obrigatório
          </span>
          <span v-if="!$v.user.cpf.minLength" class="invalid-feedback">
            Formato inválido <br />
            Ex: 000.000.000-00
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>E-mail: <span class="text-danger">*</span></label>
          <input
            type="email"
            class="form-control"
            :class="{ 'is-invalid': $v.user.email.$error }"
            v-model="$v.user.email.$model"
            placeholder="usuario@email.com.br"
          />
          <span
            v-if="!$v.user.email.required"
            class="invalid-feedback text-white"
          >
            O campo é obrigatório
          </span>
          <span v-if="!$v.user.email.email" class="invalid-feedback text-white">
            Formato de e-mail inválido
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Telefone:</label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.phone.$error }"
            placeholder="(00) 98888-8888"
            v-model="$v.user.phone.$model"
            :mask="['(##) ####-####', '(##) #####-####']"
            :masked="true"
          />
          <span
            v-if="!$v.user.phone.minLength"
            class="invalid-feedback text-white"
          >
            Formato de telefone inválido <br />
            Ex: (00) 98888-8888 / (00) 8888-8888
          </span>
        </div>
      </div>

      <hr />
      <!-- ENDEREÇO -->
      <div class="form-row">
        <div class="form-group col-sm-8">
          <label>Endereço: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.endereco.$error }"
            placeholder="Rua, Av., Tv..."
            v-model="$v.user.endereco.$model"
          />
          <span
            v-if="!$v.user.endereco.required"
            class="invalid-feedback text-white"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Nº: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.num_endereco.$error }"
            placeholder="0000"
            v-model="$v.user.num_endereco.$model"
          />
          <span
            v-if="!$v.user.num_endereco.required"
            class="invalid-feedback text-white"
          >
            O campo é obrigatório
          </span>
        </div>
      </div>

      <!-- BAIRRO, ESTADO E CIDADE -->
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Bairro:</label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.bairro.$error }"
            placeholder="Nome do bairro"
            v-model="$v.user.bairro.$model"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Estado: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.user.city_state_id.$error }"
            v-model="$v.user.city_state_id.$model"
          >
            <option value="">Selecione o estado</option>
            <option
              v-for="state in states"
              :key="state.id"
              :value="state.id"
              :title="`${state.uf} - ${state.name}`"
            >
              {{ state.uf }}
            </option>
          </select>
          <span
            v-if="!$v.user.city_state_id.required"
            class="invalid-feedback text-white"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-4">
          <label>Cidade: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.user.city_id.$error }"
            v-model="$v.user.city_id.$model"
          >
            <option value="">Selecione uma cidade</option>
            <option
              v-for="city in cities"
              :key="city.id"
              :value="city.id"
              :title="city.name"
            >
              {{ city.name }}
            </option>
          </select>
          <span
            v-if="!$v.user.city_id.required"
            class="invalid-feedback text-white"
          >
            O campo é obrigatório
          </span>
        </div>
      </div>

      <!-- ENDEREÇO -->
      <div class="form-row">
        <div class="form-group col-sm-8">
          <label>Complemento:</label>
          <input
            type="text"
            class="form-control"
            placeholder="Complemento do endereço"
            v-model="user.complemento"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>CEP:<span class="text-danger">*</span></label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.user.cep.$error }"
            placeholder="00000-000"
            v-model="$v.user.cep.$model"
            :mask="'#####-###'"
            :masked="true"
          />
        </div>
        <span
          v-if="!$v.user.city_id.required"
          class="invalid-feedback text-white"
        >
          O campo é obrigatório
        </span>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="submit"
          class="btn btn-success"
          title="Cadastrar"
          :disabled="$v.$invalid || disableButton"
        >
          Salvar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'EditarMeusDados',

  components: {
    TheMask,
  },

  data() {
    return {
      user: {
        name: '',
        email: '',
        phone: '',
        data_nascimento: '',
        cpf: '',
        confirmPassword: '',
        genero: '',
        endereco: '',
        num_endereco: '',
        bairro: '',
        city_id: '',
        city_state_id: '',
        cep: '',
        complemento: '',
      },
      states: null,
      cities: null,
      disableButton: false,
      loadDataEditing: true,
    }
  },

  watch: {
    'user.city_state_id': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.user.city_id = ''
        }
        if (newValue !== '') {
          this.getCities()
        }
      },
    },
  },

  methods: {
    async getUser() {
      try {
        this.user = await this.$store.dispatch('getUser')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            toaster: 'b-toaster-top-center',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              toaster: 'b-toaster-top-center',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            toaster: 'b-toaster-top-center',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              toaster: 'b-toaster-top-center',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCities() {
      try {
        this.cities = await this.$store.dispatch(
          'getCities',
          this.user.city_state_id,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            toaster: 'b-toaster-top-center',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              toaster: 'b-toaster-top-center',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          response = await this.$store.dispatch('updateUser', this.user)
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            toaster: 'b-toaster-top-center',
            variant: 'success',
            solid: true,
          })
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              toaster: 'b-toaster-top-center',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                toaster: 'b-toaster-top-center',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    user: {
      name: {
        required,
        minLength: minLength(8),
      },
      email: {
        required,
        email,
      },
      phone: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      data_nascimento: {
        required,
      },
      cpf: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(14),
      },
      genero: {
        required,
      },
      endereco: {
        required,
      },
      num_endereco: {
        required,
      },
      bairro: {
        required,
      },
      city_id: {
        required,
      },
      city_state_id: {
        required,
      },
      cep: {
        required,
      },
    },
  },

  mounted() {
    this.getStates()
    this.getUser()
  },
}
</script>
